import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    projectId:-1,
    unit_category_id:-1
};

const newLeadSlice = createSlice({
    name: "newLeadState",
    initialState,
    reducers: {
        setLeadInfo: (state, { payload }) => {
            console.log("YPYPYPYP", payload);
            state.projectId = payload.projectId;
            state.unit_category_id = payload.unit_category_id
        },

    },
});

export const { setLeadInfo } = newLeadSlice.actions;

export default newLeadSlice.reducer;

