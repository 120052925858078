// All components mapping with path for internal routes
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
import { lazy } from 'react'
// Import components lazily
const HomePage = lazy(() => import('../pages/HomePage'))
const BlogListing = lazy(() => import('../pages/BlogListing'))
const VideoListing = lazy(() => import('../pages/VideoListing'))

const FavoritesPage = lazy(() => import('../pages/protected/FavoritesPage'))
const AccountPage = lazy(() => import('../pages/protected/AccountPage'))

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/blogs',
        component: BlogListing,
    },
    {
        path: '/videos',
        component: VideoListing,
    },
    {
        path: '/favorite',
        component: FavoritesPage,
    },
    {
        path: '/my-account',
        component: AccountPage,
    }
]

export default routes