import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchVideoById } from "../services/videoService";
import { baseUrl } from "../config/url";
import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // Import the CSS for nprogress
import VideoComponent from "../components/homepage/VideoComponent";

const VideoPage = () => {
	const navigate = useNavigate();
	const [video, setVideo] = useState(null);
	const location = useLocation();
	const videoId = location.pathname.split("/")[2];

	const logVideoViews = async (videoId) => {
		try {
			await axios.get(`${baseUrl}views/${videoId}`);
		} catch (error) {
			console.error("Error calling views API:", error);
		}
	};


   
	const fetchVideo = async (id) => {
		NProgress.start();
		try {
			const response = await fetchVideoById(id);
			setVideo(response.data);
			await logVideoViews(response.data?._id);
		} catch (error) {
			console.error("Error fetching video data:", error);
		} finally {
			NProgress.done();
		}
	};

	useEffect(() => {
		if (videoId) {
			fetchVideo(videoId);
		}
	}, [videoId]);

	return (
		<div className='relative flex flex-col items-center p-4 h-screen border-2 border-base-500'>
			<div className='flex flex-col lg:flex-row items-center w-full max-w-5xl mx-auto h-full'>
				{/* Video Section */}
				<div className='w-full lg:w-fit bg-gray-200 shadow-2xl h-[75vh] lg:h-full p-2 rounded-t-xl sm:rounded-l-xl'>
					{video && <VideoComponent videoUrl={video.url} />}
				</div>

				{/* Details Section */}
				<div className='w-full lg:w-1/2 bg-white shadow-2xl p-6 overflow-y-auto h-full rounded-b-xl sm:rounded-r-xl'>
					{video && (
						<div>
							<h1 className='text-xl lg:text-2xl font-medium lg:font-bold mb-1 lg:mb-4'>{video.title}</h1>
							<h2 className='text-sm lg:text-lg text-gray-700 mb-2 lg:mb-4'>{video.subtitle}</h2>
                            <div>

                            </div>
						</div>
					)}
				</div>
			</div>

			<div className='text-base-200 bg-base-600 absolute z-50 bottom-6 right-1 sm:right-20 border-2 border-base-200 rounded-full p-4 shadow-4xl cursor-pointer'>
				<svg
					onClick={() => navigate("/")}
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth={1.5}
					stroke='currentColor'
					className='size-8'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
					/>
				</svg>
			</div>
		</div>
	);
};

export default VideoPage;
