import instance from "../config/api";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/

export const postPutFavoriteContent = async (contentId, contentType) => {
    try {
        const response = await instance({
            url: `projectRouter/content-fav/${contentId}/${contentType}`,  // Fetches Project By Id
            method: 'POST',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const postContentShare = async (data) => {
    console.log("Share Data", data);
    try {
        const response = await instance({
            url: `projectRouter/content-share`,  // Fetches Project By Id
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (error) {
        return error;
    }
};