import React from 'react';
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const Loader = () => {
    return (
        <div className='abolute z-50 flex items-center justify-center w-screen min-h-screen max-h-screen bg-base-600'>
            <img src="https://flatsinkolhapur.com/assets/loader.gif" className='w-52' alt="Loading..." fetchpriority="high"/>
        </div>
    );
};

export default Loader;
