import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    downloadState: false,
    projectId:-1,
    unit_category_id:-1
};

const downloadSlice = createSlice({
    name: "downloadState",
    initialState,
    reducers: {
        setProjectId: (state, { payload }) => {
            console.log("YPYPYPYP", payload.projectId);
            state.projectId = payload.projectId;
            state.unit_category_id = payload.unit_category_id
        }, 
        setDownloadState: (state, { payload }) => {
            state.downloadState = payload.downloadState;
        },
        setReset: (state) => {
            state.downloadState = false;
        }

    },
});

export const { setDownloadState, setReset, setProjectId } = downloadSlice.actions;

export default downloadSlice.reducer;

