import instance from "../config/api";
import { getCurrentLead } from "./authService";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
export const fetchAllLocations = async () => {
    try {
        const response = await instance({
            url: `projectRouter/locations`,  // Fetches all Projects
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const fetchFilterProps = async () => {
    try {
        const response = await instance({
            url: `projectRouter/filters`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
}

export const searchProps = async (data) => {
    const resp = await getCurrentLead();
    let newParams = {};
    console.log(data);
    if (resp?.data?._id) {
        newParams = { lead: resp.data._id };
    }
    try {
        const response = await instance({
            url: `search`,
            method: 'POST',
            data : data,
            params : newParams
        });
        return response.data;
    } catch (error) {
        return error;
    }
}