import instance from "../config/api";
import { getCurrentLead } from "./authService";

// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/



export const fetchVideoById = async (id) => {
    try {
        const response = await instance({
            url: `videoRouter/videos/${id}`,  // Fetches Project By Id
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const fetchAllVideos = async ({ params }) => {
    const resp = await getCurrentLead();
    let newParams = params;
    if (resp?.data?._id) {
        newParams = { ...params, lead: resp.data._id };
    }
    // console.log(newParams);
    try {
        const response = await instance({
            url: `videoRouter/videos`,  // Fetches all Projects
            method: 'GET',
            params: newParams
        });
        return response.data.data;
    } catch (error) {
        return error;
    }
};

