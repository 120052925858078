import instance from "../config/api";
import { getCurrentLead } from "./authService";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/



export const fetchBlogByUrl = async (blogLink) => {
    try {
        const response = await instance({
            url: `blogRouter/getBlogByLink/${blogLink}`,  // Fetches Project By Id
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};


export const fetchAllBlogs = async ({ params }) => {
    const resp = await getCurrentLead();
    let newParams = params;
    if (resp?.data?._id) {
        newParams = { ...params, lead: resp.data._id };
    }
    // console.log(newParams);
    try {
        const response = await instance({
            url: `blogRouter/blogs`,  // Fetches all Projects
            method: 'GET',
            params: newParams
        });
        return response.data.data;
    } catch (error) {
        return error;
    }
};
