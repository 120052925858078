import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    favorites: [],
};

const favoriteSlice = createSlice({
    name: "favorites",
    initialState,
    reducers: {
        setFavorites: (state, { payload }) => {
            console.log(payload);
            // console.log("Payload Of Projects", payload);
            state.favorites = payload;
        },
        handleRemoval: (state, { payload }) => {
            console.log(payload);
            const projectIndex = state.favorites.findIndex(
                (fav) => fav._id === payload.favoriteId
            );

            // If the project is found, remove it from the favorites array
            if (projectIndex !== -1) {
                state.favorites.splice(projectIndex, 1);
            }
            console.log("New state", state);
        }

    },
});

export const { setFavorites, handleRemoval } = favoriteSlice.actions;

export default favoriteSlice.reducer;

