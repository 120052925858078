import React, { useEffect, useRef } from "react";

const VideoComponent = ({ videoUrl }) => {
  const videoRef = useRef();

  useEffect(() => {
    const handleUserInteraction = () => {
      if (videoRef.current) {
        videoRef.current.muted = false; // Unmute the video
        videoRef.current.play(); // Play the video
        window.removeEventListener("click", handleUserInteraction); // Remove listener
      }
    };

    // Add a click listener to unmute and play the video
    window.addEventListener("click", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  // Ensure replay works properly
  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset the video to the beginning
      videoRef.current.play(); // Start playback again
    }
  };

  return (
    <div className="w-full h-full">
      <video
        ref={videoRef}
        src={videoUrl}
        controls
        controlsList="nodownload"
        autoPlay
        muted
        playsInline
        className="w-full h-full object-contain rounded-xl shadow-xl border-2 border-nav-500"
        onEnded={handleReplay} // Replay video when it ends
      ></video>
    </div>
  );
};

export default VideoComponent;
