import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    blogs: [],
    totalBlogs: 0,
    hasMore: true
};

const blogSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        setBlogs: (state, { payload }) => {
            console.log("Payload", payload);
            state.blogs = payload.newFavBlog || [];
            state.totalBlogs = payload?.totalBlogs || 0;
            state.hasMore = payload?.hasMoreBlog;
        },
        setNextBlogs: (state, { payload }) => {
            state.blogs = state.blogs.concat(payload.newFavBlog || []);
            state.hasMore = payload.hasMoreBlog;
            if (payload.totalBlogs) {
                state.totalBlogs = payload.totalBlogs;
            }
        },
        setHasMore: (state, { payload }) => {
            state.hasMore = payload.doesHaveMore;
        },

        setLikeBlog: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            console.log(payload.contentId);
            const blogIndex = state.blogs.findIndex(
                (blog) => blog._id === payload.contentId
            );
            console.log(blogIndex, "PRI");
            console.log(state.blogs[blogIndex], state.blogs);
            if (blogIndex !== -1) {
                // Toggle the isFav value
                state.blogs[blogIndex].isFav = !state.blogs[blogIndex].isFav;
                if (state.blogs[blogIndex].isFav) {
                    state.blogs[blogIndex].totalFavorites += 1;
                }
                else {
                    state.blogs[blogIndex].totalFavorites -= 1;
                }
            }
        }
    },
});

export const { setBlogs, setNextBlogs, setHasMore, setLikeBlog } = blogSlice.actions;

export default blogSlice.reducer;

