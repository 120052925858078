import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    type: null,
    config: null,
    area: null,
    minPrice: 100000,
    maxPrice: 25000000,
    nearByPlaces: null,
    title: null,
    possessionStatus: null,
    projectOffset: 0,
    contentOffset: 0,
    lastContentPosition: 0,
    screenSize: 'md',
    anonymous : null
};

const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setScreenSize: (state, { payload }) => {
            state.screenSize = payload.screenSize;
        },
        resetOffsets: (state, { payload }) => {
            state.projectOffset = 0;
            state.contentOffset = 0;
            state.lastContentPosition = -1;
        },
        setOffsets: (state, { payload }) => {
            state.projectOffset = payload.newProjectOffset || 0;
            state.contentOffset = payload.newContentOffset || 0;
            state.lastContentPosition = payload.lastContentPosition || null;
        },
        resetFilters: (state, { payload }) => {
            state.type = null;
            state.config = null;
            state.area = null;
            state.possessionStatus = null;
            state.minPrice = 100000;
            state.maxPrice = 25000000;
            state.nearByPlaces = null;
            state.title = null;
            state.anonymous = null;
        },
        setFilters: (state, { payload }) => {
            console.log("New Payload", payload);
            if (payload.type && payload.type !== 'deselect') {
                console.log("Hoyyomh");
                state.type = payload.type
                state.config = null
            }
            if (payload.type === 'deselect') {
                state.type = null
                state.config = null
            }
            if (payload.config && payload.config !== 'deselect') {
                state.config = payload.config
            }
            if (payload.config === 'deselect') {
                state.config = null
            }
            if (payload.area && payload.area !== 'deselect') {
                state.area = payload.area
            }
            if (payload.area === 'deselect') {
                state.area = null
            }
            if (payload.anonymous && payload.anonymous !== 'deselect') {
                state.anonymous = payload.anonymous
            }
            if (payload.anonymous === 'deselect') {
                state.anonymous = null
            }
            if (payload.minPrice) {
                state.minPrice = payload.minPrice
            }
            if (payload.maxPrice) {
                state.maxPrice = payload.maxPrice
            }
            if (payload.possessionStatus && payload.possessionStatus !== 'deselect' && payload.possessionStatus !== "All") {
                state.possessionStatus = payload.possessionStatus
            }
            if (payload.possessionStatus === 'deselect' || payload.possessionStatus === "All") {
                state.possessionStatus = null
            }
            if (payload.nearByPlaces) {
                state.nearByPlaces = payload.nearByPlaces
            }
            if (payload.title) {
                state.title = payload.title
            }
        },


    },
});

export const { setFilters, setOffsets, resetOffsets, setScreenSize, resetFilters } = filterSlice.actions;

export default filterSlice.reducer;
