import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { fetchBlogByUrl } from '../services/blogService';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../config/url';
import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // Import the CSS for nprogress

const BlogPage = () => {
    const navigate = useNavigate();
    const [blog, setBlog] = useState(null);
    const location = useLocation();
    const blogLink = location.pathname.split('/')[2];

    const logBlogViews = async (blogId) => {
        try {
            await axios.get(`${baseUrl}views/${blogId}`);
        } catch (error) {
            console.error('Error calling views API:', error);
        }
    };

    const fetchBlog = async (link) => {
        NProgress.start(); // Start the progress bar
        let blogData;
        try {
            const response = await fetchBlogByUrl(link);
            blogData = response.data;
            setBlog(blogData);
            
        } catch (error) {
            console.error('Error fetching blog data:', error);
        } finally {
            NProgress.done(); // Stop the progress bar
            await logBlogViews(blogData._id);
        }
    };

    useEffect(() => {
        if (blogLink) {
            fetchBlog(blogLink);
        }
    }, [blogLink]);

    return (
        <div>
            {blog && (
                <>
                    {/* React Helmet for Dynamic Meta Tags */}
                    <Helmet>
                        <title>{blog.title || 'Blog Page'}</title>
                        <meta name="description" content={blog.description || 'Read this amazing blog'} />
                        <meta property="og:title" content={blog.title || 'Blog Page'} />
                        <meta property="og:description" content={blog.description || 'Read this amazing blog'} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="article" />
                    </Helmet>

                    <div
                        className="fixed inset-0 flex items-center justify-center z-50 max-w-[2000px] mx-auto"
                        onClick={(e) => e.target === e.currentTarget && navigate(-1)}
                    >
                        <div className="relative bg-white rounded-lg shadow-lg max-w-full w-full h-full">
                            <iframe
                                src={blog.url}
                                title="Blog Content"
                                className="w-full h-full overflow-auto"
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                            <div className="text-base-200 bg-base-600 absolute z-50 bottom-6 right-1 sm:right-20 border-2 border-base-200 rounded-full p-4 shadow-4xl cursor-pointer">
                                <svg
                                    onClick={() => navigate('/')}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-8"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BlogPage;
