import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    projects: [],
    totalProjects: 0,
    hasMore: true
};

const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setProjects: (state, { payload }) => {
            state.projects = payload.data || [];
            state.totalProjects = payload?.totalProjects || 0;
            state.hasMore = payload?.hasMoreProject;
        },
        setNextProjects: (state, { payload }) => {
            state.projects = state.projects.concat(payload.data || []);
            state.hasMore = payload.hasMoreProject;
            if (payload.totalProjects) {
                state.totalProjects = payload.totalProjects;
            }
        },
        setHasMore: (state, { payload }) => {
            state.hasMore = payload.doesHaveMore;
        },

        setLikeProject: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            const projectIndex = state.projects.findIndex(
                (project) => project._id === payload.projectId
            );

            if (projectIndex !== -1) {
                // Toggle the isFav value
                state.projects[projectIndex].isFav = !state.projects[projectIndex].isFav;

                if (state.projects[projectIndex].isFav) {
                    state.projects[projectIndex].totalFavorites += 1;
                }
                else {
                    state.projects[projectIndex].totalFavorites -= 1;
                }
            }
        },

        setShareProject: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            const projectIndex = state.projects.findIndex(
                (project) => project._id === payload.projectId
            );
            console.log(state.projects[projectIndex]);
            state.projects[projectIndex].totalShares += 1;
        },
        setShareContent: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            const contentIndex = state.projects.findIndex(
                (project) => project.contentId === payload.contentId
            );
            console.log(state.projects[contentIndex]);
            state.projects[contentIndex].totalShares += 1;
        },
        setLikeContent: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            console.log(payload.contentId);
            const projectIndex = state.projects.findIndex(
                (project) => project.contentId === payload.contentId
            );
            console.log(projectIndex, "PRI");
            console.log(state.projects[projectIndex], state.projects);
            if (projectIndex !== -1) {
                // Toggle the isFav value
                state.projects[projectIndex].isFav = !state.projects[projectIndex].isFav;
                if (state.projects[projectIndex].isFav) {
                    state.projects[projectIndex].totalFavorites += 1;
                }
                else {
                    state.projects[projectIndex].totalFavorites -= 1;
                }
            }
        }
    },
});

export const { setProjects, setNextProjects, setHasMore, setLikeProject, setLikeContent, setShareProject, setShareContent } = projectSlice.actions;

export default projectSlice.reducer;

