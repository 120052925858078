import { combineReducers, configureStore } from "@reduxjs/toolkit";
import projectReducer from "./features/projectSlice";
import blogReducer from "./features/blogSlice";
import videoReducer from "./features/videoSlice";
import filterReducer from "./features/filterSlice";
import favoriteReducer from "./features/favoriteSlice";
import newLeadReducer from './features/leadSlice';
import authFunctionalityReducer from "./features/authFunctionalitySlice";
import downloadReducer from "./features/downloadSlice";
import searchReducer from "./features/searchSlice";
import uiReducer from "./features/uiSlice";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const rootReducer = combineReducers({
    projects: projectReducer,
    blogs: blogReducer,
    videos: videoReducer,
    filters: filterReducer,
    favorites: favoriteReducer,
    authFunctions: authFunctionalityReducer,
    downloadState: downloadReducer,
    search: searchReducer,
    leadInfo: newLeadReducer,
    ui: uiReducer
});

export const store = configureStore({
    reducer: rootReducer,
});






// import storage from "redux-persist/lib/storage";
// import { persistReducer, persistStore } from "redux-persist";

// const persistCreateUserFormConfig = {
//     key: " createUser",
//     storage,
// };
// createUser: persistReducer(persistCreateUserFormConfig, createUserReducer),
// export const persistor = persistStore(store);
