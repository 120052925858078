import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import {
	fetchProjectByDomain,
	handleDownload,
	postLeadToProject,
	postViewToProject,
} from "../services/projectService";
import { getCurrentLead } from "../services/authService";
import { useAuth } from "../contexts/AuthProvider";
import { assetsUrl } from "../config/url";
import RenderCarousel from "../components/dynamicpage/RenderCarousel";
import ImgGallery from "../components/dynamicpage/ImgGallery";
import ContactUs from "../components/ContactUs";
import { toast } from "react-toastify";
import { setFunction } from "../store/features/authFunctionalitySlice";
import { useDispatch, useSelector } from "react-redux";
import {
	setDownloadState,
	setProjectId,
} from "../store/features/downloadSlice";
import ReactGA from "react-ga4";

import NProgress from "nprogress";
import "nprogress/nprogress.css"; // Import the CSS for nprogress
import { setLeadInfo } from "../store/features/leadSlice";

function WebsitePage() {
	const navigate = useNavigate("/");
	const downloadState = useSelector(
		(state) => state.downloadState.downloadState
	);
	const leadState = useSelector(
		(state) => state.leadInfo
	);
	const dispatch = useDispatch();
	const location = useLocation();
	const domain = location.pathname.split("/")[1];
	const [project, setProject] = useState(null);
	let pId = -1;
	let projectId = -1;
	let hConfig = -1;
	const { openLogin, loginOpen, closeLogin } = useAuth();
	const [highestConfig, setHigestConfig] = useState(null);
	const fetchProject = async (domain) => {
		NProgress.start(); // Start the progress bar

		try {
			const response = await fetchProjectByDomain(domain);
			console.log(response.data);
			const projectData = response.data;

			if (
				projectData.projectId.type === "flat" ||
				projectData.projectId.type === "rowhouse"
			) {
				console.log(
					projectData?.projectId?.configurations[
						projectData?.projectId?.configurations?.length - 1
					]?.["config"][0]
				);
				setHigestConfig(
					projectData?.projectId?.configurations[
						projectData?.projectId?.configurations?.length - 1
					]?.["config"][0]
				);
				hConfig = projectData?.projectId?.configurations[
					projectData?.projectId?.configurations?.length - 1
				]?.["config"][0]
			} else if (projectData.projectId.type === "plot") {
				setHigestConfig(1);
				hConfig = 1;
			} else if (projectData.projectId.type === "shop") {
				setHigestConfig(5);
				hConfig = 5;
			} else if (projectData.projectId.type === "office") {
				setHigestConfig(6);
				hConfig = 6;
			}
			pId = projectData.projectId.leedoProjectId
			projectId =  projectData.projectId._id;
			// Format the data as per your requirement
			const fetchedProject = {
				metaDetails: projectData.projectId.metaDetails,
				leedoProjectId: projectData.projectId.leedoProjectId,
				projectId: projectData.projectId._id,
				title: projectData.projectId.title,
				subtitle: projectData.details.subtitle,
				description: projectData.projectId.description,
				logo: projectData.projectId.logoUrl,
				gallery: projectData.details.gallery,
				floorPlan: projectData.details.floorPlan,
				type: projectData.projectId.type,
				location:
					projectData.projectId.location.area +
					", " +
					projectData.projectId.city,
				projectFeatures: projectData.details.projectFeatures,
				nearByPlaces:
					projectData.projectId.nearByPlaces?.length >= 0
						? projectData.projectId.nearByPlaces?.map((place, index) => {
								return place["name"];
						  })
						: "N/A",
				externalSpecifications: projectData.details.externalFeatures,
				internalSpecifications: projectData.details.internalFeatures,
				configuration:
					projectData.projectId.configurations?.length >= 0
						? projectData.projectId.configurations
								?.map((config) => {
									return config.config;
								})
								.join(" , ")
						: "N/A",

						configurationArray: projectData.projectId.configurations?.map(config => ({
							_id: config._id,
							config: config.config,
							quantity: config.quantity,
							area: config.area,
							areaUnit: config.areaUnit,
							price: config.price,
							priceUnit: config.priceUnit,
						  })) || [],


						
				locationLink: projectData.projectId.location.gmap,
				status: projectData.projectId.possessionStatus,
				possessionBy:
					projectData.details.possessionBy ||
					projectData.projectId.possessionDate,
				reraNo: projectData.details.reraNo || projectData.projectId.reraNo,
				constructionGallery: projectData.details.constructionGallery,
				brochure: projectData.details.brochure[0],
				displayImage: projectData.projectId.displayImage,
			};
			setProject(fetchedProject);
		} catch (error) {
			// console.error("Error fetching project data:", error.status);
			openLogin();
		} finally {
			NProgress.done(); // Stop the progress bar
		}
	};

	//Handle Download Brochure Logic -
	const handleDownloadBrochure = async () => {
		ReactGA.event({
			category: "User Interaction",
			action: "click",
			label: `${domain} button`, // Optional
		});
		console.log("FULL PROJECT",

		);
		if (project?.type === "flat") {
			console.log("HIGHEST CONFIG", highestConfig);
		}
		dispatch(setDownloadState({ downloadState: true }));
		const resp = await getCurrentLead();
		if (resp.status > 250) {
			const authAction = {
				purpose: "function",
				details: {
					actionName: "downloadBrochure",
					data: {
						brochure: {
							brochureurl: `${project?.brochure}`,
						},
						projectId: project?.projectId,
					},
				},
			};
			dispatch(setFunction(authAction));
			openLogin();
		} else {
			closeLogin();
			let resp2 = "";
			if (project?.projectId) {
				resp2 = await postLeadToProject(
					project?.projectId,
				);
			}
			if (resp2.status === "success") {
				await handleDownload({ brochureurl: `${project?.brochure}` });
				toast("Pdf Download Successful");
			}
			dispatch(setDownloadState({ downloadState: false }));
		}
	};

	const checkUserLogin = async() => {
		const resp = await getCurrentLead();
		if (resp.status > 250) {
			const authAction = {
				purpose: 'redirect',
				details : {
					actionName : 'redirectToFav',
					data : {
						redirectPath : `/${domain}`
					}
				}
			}
			dispatch(setFunction(authAction));
			openLogin();
		} else {
			closeLogin();
			console.log("P ID DDDD",project?.projectId)
			await postViewToProject(
				projectId,
			);
		}
	}
	// Fetch or set project data here
	const takingStateLogic = async() =>{
		await fetchProject(domain);
		console.log("LEADDDDDDDDDDd",leadState,pId, hConfig);
		dispatch(setLeadInfo({projectId: pId, unit_category_id: hConfig}));
		checkUserLogin();
	}
	useEffect(() => {
		// console.log(domain);

		takingStateLogic();
		
	}, [loginOpen]);

	const sanitizeHtml = (html) => {
		return html.replace(/<script[^>]*>|<\/script>/gi, "");
	};

	return (
		<>
			{project ? (
				<div className='px-4 sm:px-8 md:px-16 pb-5 mx-auto max-w-[1500px] relative'>
					<Helmet>
						<meta
							name={`Website of ${
								project?.metaDetails?.title
									? project?.metaDetails?.title
									: project?.title
							}`}
							content='width=device-width, initial-scale=1.0'
						/>
						<meta
							property='title'
							content={`${
								project?.metaDetails?.title
									? project?.metaDetails?.title
									: project?.title
							}`}
						/>
						<meta
							property='description'
							content={`${
								project?.metaDetails?.description
									? project?.metaDetails?.description
									: project?.description
							}`}
						/>{" "}
						<meta
							property='keywords'
							content={`${
								project?.metaDetails?.keywords
									? project?.metaDetails?.keywords
									: ""
							}`}
						/>
						<meta
							property='og:title'
							content={`${
								project?.metaDetails?.title
									? project?.metaDetails?.title
									: project?.title
							}`}
						/>
						<meta
							property='og:description'
							content={`${
								project?.metaDetails?.description
									? project?.metaDetails?.description
									: project?.description
							}`}
						/>
						<meta
							property='og:image'
							content={`${`${project?.displayImage}`}`}
						/>
						<meta
							property='og:url'
							content={`${`https://flatsinkolhapur.com/${domain}`}`}
						/>
						<meta property='og:type' content='website' />
						<title>
							{project?.metaDetails?.title
								? project?.metaDetails?.title
								: project?.title}
						</title>
					</Helmet>

					<div className='max-w-xs mt-5 mb-8'>
						<img
							src={`${project?.logo}`}
							alt='Project Logo'
							className='h-auto w-[150px] object-cover'
						/>
					</div>

					<div className='grid grid-cols-1 md:grid-cols-3  '>
						<div className='bg-[#a17945] p-6 text-sm h-full md:col-span-2 flex flex-col justify-between'>
							<div className='flex flex-col gap-2'>
								<h2 className='text-2xl sm:text-4xl'>{project?.subtitle}</h2>
								<p className='text-md sm:text-lg'>{project?.description}</p>
							</div>

							<div className='mt-5 grid grid-cols-1 md:grid-cols-4 gap-2 text-lg'>
								<div>
									<p>Configuration</p>

									<p className='text-lg sm:text-xl font-bold'>
										{(() => {
											const configuration = project?.configuration || ""; // Get the configuration string or empty string if not present
											const match = configuration.match(/\d+/); // Find where the number starts

											if (match) {
												// If a number is found, return the substring starting from that number's position
												const index = match.index;
												return configuration.substring(index);
											}

											return configuration; // If no number is found, return the whole string
										})()}
									</p>
								</div>
								<div>
									<p>Location</p>
									<p className='text-lg sm:text-xl font-bold'>
										{project?.location}
									</p>
								</div>
								<div>
									<p>Status</p>
									<p className='text-lg sm:text-xl font-bold'>
										{project?.status}
									</p>
								</div>
								<div>
									<p>Possession By</p>

									<p className='text-lg sm:text-xl font-bold'>
										{project?.possessionBy}
									</p>
								</div>
							</div>
						</div>

						<div className='bg-[#ecedef] text-center p-3 py-6'>
							<img
								src='/assets/images/rera-logo.png'
								alt='RERA Logo'
								className='mx-auto mb-2 h-32'
							/>
							<h3 className='text-xl sm:text-2xl'>MahaRERA Registration No.</h3>
							<p className='text-lg sm:text-xl font-bold'>{project?.reraNo}</p>
							<div className='flex flex-col items-center justify-center'>
								<p className='text-md sm:text-lg mt-2'>Available at</p>
								<a
									href='https://maharera.maharashtra.gov.in'
									target='_blank'
									rel='noopener noreferrer'
									className='text-black no-underline text-md sm:text-lg text-blue-500'>
									https://maharera.maharashtra.gov.in
								</a>
							</div>
						</div>
					</div>
					<div
						className={`grid grid-cols-1 gap-2 my-2  overflow-hidden custom-height ${
							project?.projectFeatures ? "md:grid-cols-3" : "md:grid-cols-2"
						}`}>
						{/* First Column: File Upload Section */}
						<div className='md:col-span-2 h-fit'>
							<div className='h-full'>
								<h5 className='text-2xl sm:text-4xl my-4'>Gallery</h5>
								{project?.gallery?.length > 0 ? (
									<div className='w-full mt-2 h-fit '>
										<RenderCarousel type='gallery' file={project?.gallery} />
									</div>
								) : (
									"Gallery will be added soon"
								)}
							</div>
						</div>

						{/* Second Column: Project Features */}
						{project?.projectFeatures && (
							<div className='flex flex-col h-[983px]  overflow-hidden   '>
								<h5 className='text-2xl sm:text-4xl my-4'>Features</h5>
								<div className='flex-1 flex flex-col overflow-y-auto filter'>
									<div className='bg-base-600 text-base-100 flex-1 p-6 overflow-y-auto'>
										<div className='flex flex-col items-start gap-2'>
											{project.projectFeatures.map((feature, index) => (
												<div
													className='flex items-start mb-2 text-xl sm:text-2xl'
													key={index}>
													{/* Bullet Point */}
													<span className='block h-2 w-2 bg-base-100 mr-2 mt-2.5' />
													{/* Feature Text */}
													<span className='flex-1 flex items-start'>
														{feature}
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>


					{downloadState ? (
						<button
							disabled
							className='bg-base-500 text-base-100 mt-10 text-white py-2 px-4 rounded mb-2 text-xl'>
							Downloading...
						</button>
					) : (
						<button
							onClick={() => {
								handleDownloadBrochure();
							}}
							className='bg-base-600 text-base-100 mt-10 text-white py-2 px-4 rounded mb-2 text-xl'>
							Download Brochure
						</button>
					)}

					<div className='my-2 grid grid-cols-1 md:grid-cols-3 gap-2 '>
						<div className='md:col-span-2'>
							<h2 className='text-2xl sm:text-4xl my-4'>Plan</h2>
							{project?.floorPlan?.length > 0 ? (
								<div className='mt-2'>
									<RenderCarousel type='floorPlan' file={project?.floorPlan} />
								</div>
							) : (
								"Plan will be added soon"
							)}
						</div>



						<div className="md:col-span-1  h-[320px]">
  <h2 className="text-2xl sm:text-4xl my-4">Details</h2>
							<div className=" border rounded-lg shadow-lg bg-white">
  <div className="space-y-4">
  <div className="table w-full border-collapse border border-gray-300">
  <div className="table-row">
    <div className="table-cell border border-gray-300 p-2 font-medium text-lg">Type</div>
    <div className="table-cell border border-gray-300 p-2 text-lg text-gray-600">Available Units</div>
	<div className="table-cell border border-gray-300 p-2 text-lg text-gray-600">Area in Sq. Ft. <span className="text-sm">(approx.)</span></div>
  </div>
  {project.configurationArray.map((config) => {
 

          return (
            <div key={config._id} className="table-row">
              <div className="table-cell border border-gray-300 p-2 text-lg text-gray-600">
                {config.config}
              </div>
              <div className="table-cell border border-gray-300 p-2 text-lg text-gray-600">
                {config.quantity} units
              </div>
              <div className="table-cell border border-gray-300 p-2 text-lg text-gray-600">
			  {config.area}
              </div>
            </div>
          );
        })}

</div>

  </div>
</div>
</div>






						{/* <div className='md:col-span-1'>
							<h2 className='text-2xl sm:text-4xl my-4'>
								Project feature
							</h2>
							{project?.constructionGallery?.length > 0 ? (
								<ImgGallery imagesArray={project?.constructionGallery} />
							) : (
								<div className='grid grid-cols-2 gap-2 w-full h-full'>
									<img
										src={`${assetsUrl}/assets/images/images.jpg`}
										alt='Construction Gallery'
										className='h-[100px] sm:h-[100px] object-cover'
									/>
									<img
										src={`${assetsUrl}/assets/images/images.jpg`}
										alt='Construction Gallery '
										className='h-[100px] sm:h-[100px] object-cover'
									/>
									<img
										src={`${assetsUrl}/assets/images/images.jpg`}
										alt='Construction Gallery'
										className='h-[100px] sm:h-[100px] object-cover'
									/>
									<img
										src={`${assetsUrl}/assets/images/images.jpg`}
										alt='Construction Gallery'
										className='h-[100px] sm:h-[100px] object-cover'
									/>
									<img
										src={`${assetsUrl}/assets/images/images.jpg`}
										alt='Construction Gallery '
										className='h-[100px] sm:h-[100px] object-cover'
									/>
									<img
										src={`${assetsUrl}/assets/images/images.jpg`}
										alt='Construction Gallery'
										className='h-[100px] sm:h-[100px] object-cover'
									/>
								</div>
							)}
						</div> */}
					</div>

					{/* <>
					{downloadState ? (
						<button
							disabled
							className='bg-base-500 text-base-100 mt-10 text-white py-2 px-4 rounded mb-2 text-xl'>
							Downloading...
						</button>
					) : (
						<button
							onClick={() => {
								handleDownloadBrochure();
							}}
							className='bg-base-600 text-base-100 mt-10 text-white py-2 px-4 rounded mb-2 text-xl'>
							Download
						</button>
					)}
					</> */}

					<h2 className='text-2xl sm:text-4xl my-6'>
						{project?.internalSpecifications ||
						project?.externalSpecifications ? (
							<>Specifications</>
						) : (
							""
						)}
					</h2>
					<div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-2 bg-base-300 text-base-600 px-4'>
						{project?.internalSpecifications && (
							<div className='flex flex-col items-start gap-2 overflow-auto p-2'>
								<div className='flex items-start mb-2'>
									<span className='block h-2 w-2 bg-base-600 mr-2 mt-6'></span>
									<h5 className='text-lg sm:text-xl my-4 font-bold'>
										INTERNAL SPECIFICATIONS
									</h5>
								</div>

								{project?.internalSpecifications?.map((feature, index) => (
									<div
										className='flex items-start mb-2 text-lg sm:text-xl '
										key={index}>
										<span className='block h-2 w-2 bg-base-600 mr-2 mt-2.5' />
										<span className='flex-1 flex items-start'>{feature}</span>
									</div>
								))}
							</div>
						)}
						{project?.externalSpecifications && (
							<div className='flex flex-col items-start gap-2 overflow-auto p-2'>
								<div className='flex items-start mb-2'>
									<span className='block h-2 w-2 bg-base-600 mr-2 mt-6'></span>
									<h5 className='text-lg sm:text-xl  my-4 font-bold'>
										EXTERNAL SPECIFICATIONS
									</h5>
								</div>

								{project?.externalSpecifications?.map((feature, index) => (
									<div
										className='flex items-start mb-2 text-lg sm:text-xl '
										key={index}>
										<span className='block h-2 w-2 bg-base-600 mr-2 mt-2.5' />
										<span className='flex-1 flex items-start'>{feature}</span>
									</div>
								))}
							</div>
						)}
					</div>

					<div className='my-1 grid grid-cols-1 md:grid-cols-3 gap-2'>
						<div className='flex flex-col min-h-fit p-0'>
							<div className='flex-1 min-h-fit flex flex-col'>
								<h2 className='text-4xl my-4'>Nearby Places</h2>
								<div className='bg-base-300 flex-1'>
									<ul className='p-6 space-y-2 overflow-auto'>
										{project?.nearByPlaces?.map((nearByPlace, index) => (
											<li
												className='flex items-start mb-2 text-lg sm:text-xl'
												key={index}>
												<span className='block h-2 w-2 bg-base-600 mr-2 mt-2.5' />
												<span className='flex-1 flex items-start justify-between gap-4'>
													<div>{nearByPlace.split("-")[0]}</div>
													<div>{nearByPlace.split("-")[1]}</div>
												</span>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>

						<div className='md:col-span-2'>
							<h2 className='text-4xl my-4'>Location Map</h2>
							<div
								dangerouslySetInnerHTML={{
									__html: sanitizeHtml(project?.locationLink),
								}}
							/>
						</div>
					</div>

					<div className='w-full h-full bg-base-600 mt-3'>
						<ContactUs />
					</div>
					<div className='text-base-100 bg-base-600 fixed z-40 bottom-6 right-2 sm:right-20 border-2 borer-base-400 rounded-full p-4 shadow-4xl'>
						<svg
							onClick={() => {
								navigate("/");
							}}
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='size-8'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
							/>
						</svg>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}

export default WebsitePage;
