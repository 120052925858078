import axios from "axios";
import instance from "../config/api";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
import { getCurrentLead } from "./authService";


const API_TOKEN = "bpngwitIrzmxSh4f4bMh4yGsQfg0Y9om7Dzc9HFhjQCe36GQtnIbAXrkA3t7";
const API_URL = "https://leedo.tribeappsoft.in/LeedoBackend2/public/api/addSalesLead";


export const fetchAllDomains = async () => {
    try {
        const response = await instance({
            url: `projectRouter/domains`,  // Fetches all Projects
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};


export const fetchAllProjects = async ({ params }) => {
    const resp = await getCurrentLead();
    let newParams = params;
    if (resp?.data?._id) {
        newParams = { ...params, lead: resp.data._id };
    }
    // console.log(newParams);
    try {
        const response = await instance({
            url: `projectRouter/content`,  // Fetches all Projects
            method: 'GET',
            params: newParams
        });
        return response.data.data;
    } catch (error) {
        return error;
    }
};

export const fetchProjectByDomain = async (domain) => {
    try {
        const response = await instance({
            url: `projectRouter/projects/${domain}`,  // Fetches Project By Id
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};





export const postLeadToProject = async (projectId) => {
    try {

        // Fetch project lead data by projectId
        const projectResponse = await instance.post(`projectRouter/projectLead/${projectId}`);
        console.log('Project response:', projectResponse.data);

        return projectResponse.data;
    } catch (error) {
        console.error('Error in postLeadToProject:', error.response ? error.response.data : error.message);
        throw error; // Re-throw to allow calling function to handle errors
    }
};
export const postViewToProject = async (projectId) => {
    try {

        // Fetch project lead data by projectId
        const projectResponse = await instance.post(`projectRouter/projectView/${projectId}`);
        console.log('Project response:', projectResponse.data);

        return projectResponse.data;
    } catch (error) {
        console.error('Error in postLeadToProject:', error.response ? error.response.data : error.message);
        throw error; // Re-throw to allow calling function to handle errors
    }
};


export const leadAPIHitting = async(leedoProjectId, highestConfig, name, phone) => {
    try{
           // Fetch current lead details
           const resp = await getCurrentLead();
           if (!resp?.data) {
               throw new Error("Failed to fetch lead details");
           }

           // Prepare lead data for API
           const leadData = {
               api_token: API_TOKEN,
               project_id: leedoProjectId,
               unit_category_id: Number(highestConfig),
               lead_stage_id: 2,
              sales_person_id: 3,
               lead_remark: "",
               lead_types_id: 13,
               full_name: resp.data.name || "Unknown",
               mobile_number: `${resp.data.phone}` || "Unknown",
               lead_status_id: 1
           };
           console.log(leadData);
           // Post lead data to API
           const leadResponse = await axios.post(API_URL, leadData,{
               headers: {
                   'Content-Type': 'application/json',
               }
           });
           console.log('Lead added successfully:', leadResponse.data);
   
    }catch (error) {
        console.error('Error in postLeadToProject:', error.response ? error.response.data : error.message);
        throw error; // Re-throw to allow calling function to handle errors
    }
}

export const postPutFavoriteProject = async (projectId) => {
    try {
        const response = await instance({
            url: `projectRouter/favorites/${projectId}`,  // Fetches Project By Id
            method: 'POST',
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const postShare = async (data) => {
    console.log("Share Data", data);
    try {
        const response = await instance({
            url: `projectRouter/share`,  // Fetches Project By Id
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (error) {
        return error;
    }
};


export const getFavorites = async () => {
    // console.log(projectId);
    try {
        const response = await instance({
            url: `projectRouter/favorites`,  // Fetches Project By Id
            method: 'GET',
        });
        console.log("RRRR", response);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getFavCount = async () => {
    try {
        const response = await instance({
            url: `projectRouter/favorites/count`,  // Fetches Project By Id
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        return error;
    }
}

export const handleDownload = async (url) => {
    console.log(url);
    try {
        const response = await axios.get(url.brochureurl, {
            responseType: 'blob', // Important for handling binary data
        });

        console.log("Response", response);
        // Extract filename from the URL
        const filename = url.brochureurl.split('/').pop(); // Get the last part of the URL

        // console.log("Filename", filename);
        // Create a URL for the file
        const blob = new Blob([response.data]);
        const downloadUrl = window.URL.createObjectURL(blob);

        // console.log("Blob", blob, "Download Url", downloadUrl);
        // Create a link element
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); // Use the extracted filename

        // console.log("Link", link);
        // Append to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
    } catch (error) {
        // console.error('Error downloading the file:', error);
    }
};
